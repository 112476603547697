/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://zgpkiivuvrd2dcngzhtzfnkzuq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ytwb52qfarfkzmnnlpcdcge4oa",
    "aws_cloud_logic_custom": [
        {
            "name": "developerApi",
            "endpoint": "https://maxb3i0adl.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:5f883c18-ac67-44e9-9ac8-70513e2686e8",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_F5rdQ51P8",
    "aws_user_pools_web_client_id": "2qeugcths2p2v6oboo80lcqt8a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "cssharedapp-20220811122258-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2py8kctz0pdeh.cloudfront.net"
};


export default awsmobile;
