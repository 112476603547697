import React, { useEffect, useState } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { linksByClientId, searchGoodLinkss } from "../../graphql/queries";
import { listSharedApps } from "../../graphql/custom-queries";
import {
  Heading,
  Button as LumosButton,
  AlertInline,
  Modal,
  ModalControls,
  Icon,
 } from "@loanpal/lumos__core";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { dateTimeStr } from "../../utils/format";
import {
  Authenticator,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SearchField,
  View,
  Flex,
  Menu,
  MenuItem,
  SelectField
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";

Amplify.configure(awsExports);

const GoodLinks = () => {
  const [SharedApps, setSharedApps] = useState([]);
  const [goodLinks, setGoodLinks] = useState([]);
  const [clientId, setClientId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const [link, setLink] = useState();


  useEffect(() => {
    fetchSharedApps();
    fetchAllLinks();
  }, []);

  useEffect(() => {
    if (clientId !== "") {
     fetchLinksByClientId();
    } else if (clientId === "") {
      // fetchLinks();
      fetchAllLinks();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  async function fetchSharedApps() {
    try {
      setSpinner(true);
      const appData = await API.graphql(graphqlOperation(listSharedApps));
      console.log(appData);
      const SharedApps = appData.data.listSharedApps.items;
      setSharedApps(SharedApps);
      setSpinner(false);
    } catch (err) {
      console.log("error fetching Shared Apps");
    }
  }

    async function fetchAllLinks() {
    try {
      const query = {
        createdAt: { exists: true }
      };
      const linkData = await API.graphql(
        graphqlOperation(searchGoodLinkss, {
          filter: { and: query },
          sort:  { field: "createdAt", direction: "desc" }
        } )
      );
      console.log(linkData);
      if (linkData.data.searchGoodLinkss.items.length > 0) {
        const links = linkData.data.searchGoodLinkss.items;
        console.log(links);
        if (links && links.length > 0) {
          setGoodLinks([...links]);
        }
      }
    } catch (err) {
      console.log("error fetching GoodLinks App", err);
    }
  }

  async function fetchLinksByClientId() {
    try {
      setSpinner(true);
      console.log(clientId);
      const linkData = await API.graphql(graphqlOperation(linksByClientId,
        { clientId: clientId,
          sort: {filter: "createdAt", direction: "desc" }
        }));
      const links = linkData.data.linksByClientId.items;
      setGoodLinks(links);
      setSpinner(false);
    } catch (err) {
      console.log("error fetching Links", err);
    }
  }

  async function fetchLinkByEmail(e) {
    if (e === "") return;
    try {
      const query = {
        custEmail: { wildcard: `*${e.toLowerCase()}*` },
      };
      const linkData = await API.graphql(
        graphqlOperation(searchGoodLinkss, { filter: { and: query } } )
      );
      if (linkData.data.searchGoodLinkss.items.length > 0) {
        const links = linkData.data.searchSharedApps.items;
        console.log(links);
        if (links && links.length > 0) {
          setGoodLinks([...links]);
        }
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
    }
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  marginBottom: "20px",
                  marginTop: "20px",
                  fontSize: "25px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <View>GoodLinks</View>
                  <Box style={styles.selectBox}>
                    <SelectField
                      label=""
                      size="small"
                      onChange={(e) => setClientId(e.target.value)}
                      style={styles.select}
                    >
                      <option value="" key="0">Please select a Client Id</option>
                      {SharedApps.map((app, index) => (
                      <option key={app.id} value={app.clientId}>{app.partnerName}</option>
                      ))}
                    </SelectField>
                  </Box>
                  </Flex>
                  <SearchField
                    label="Search"
                    placeholder="Search..."
                    size="small"
                    onSubmit={(e) => fetchLinkByEmail(e)}
                    onClear={() => fetchLinksByClientId()}
                  />
                </Flex>
              </Heading>
              {goodLinks.length ? (
                <Table caption="" highlightOnHover={false} size="small" variation="striped">
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Client Id</TableCell>
                      <TableCell as="th">Email</TableCell>
                      <TableCell as="th">First Name</TableCell>
                      <TableCell as="th">Last Name</TableCell>
                      <TableCell as="th">Created At</TableCell>
                      <TableCell as="th"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {goodLinks.map((link, index) => (
                      <TableRow key={link.id ? link.id : index}>
                        <TableCell>{link.clientId}</TableCell>
                        <TableCell>{link.custEmail}</TableCell>
                        <TableCell>{link.custFirstName}</TableCell>
                        <TableCell>{link.custLastName}</TableCell>
                        <TableCell>{dateTimeStr(link.createdAt)}</TableCell>
                        <TableCell>
                          <View width="4rem">
                            <Menu menuAlign="start" size="small">
                              <MenuItem size="small" onClick={() => {setLink(link); toggleOpen(true)}}><Icon variant="eye-open" size="sm" sx={{marginRight: '5px'}} />View</MenuItem>
                            </Menu>
                          </View>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <AlertInline variant="info">No items found.</AlertInline>
              )}
            </Box>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
          <Modal isOpen={isOpen} onDismiss={() => toggleOpen(false)} title="GoodLink">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "14px",
                }}
              >
            {JSON.stringify(null, 2, link)}
            </Heading>
            <ModalControls actions={[
                {
                  onClick: () => toggleOpen(false), label: 'Close'
                }
              ]}
            />
          </Modal>
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 20px",
    backgroundColor: "#ffffff"
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 20,
    padding: 8,
    fontSize: 16,
  },
  select: {
    width: '220px'
  },
  selectBox: {
    position: "relative",
    top: "-5px",
    left: "10px"
  },
  switch: {
    marginBottom: 10,
    padding: 8,
    fontSize: 16,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  menuButton: {
    backgroundColor: "#00838f",
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
};

export default GoodLinks;
