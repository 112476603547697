import React, { useState, useEffect } from 'react';
import { Amplify, API } from "aws-amplify";
import {
  Authenticator,
  Divider,
  PasswordField,
  SwitchField,
  TextField,
  Alert
} from "@aws-amplify/ui-react";
import { Box, Card } from "theme-ui";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import {
  Heading,
  Button as LumosButton,
  useNotify,
  Card as LumosCard,
 } from "@loanpal/lumos__core";
import Loading from "../../utils/loading";
 import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const initialState = {
  clientId: "",
  username: "",
  password: "",
  apiKeys: false
}

const App = () => {
  // Change environment dev/prod accordingly
  const [env] = useState("dev");
  const [formState, setFormState] = useState(initialState);
  const [spinner, setSpinner] = useState(false);
  const { addSnack, addToast } = useNotify();

  const setInput = (key, value) => {
    if (key === "clientId") {
      setFormState({ ...formState, [key]: value, apiKeys: false });
    } else {
      setFormState({ ...formState, [key]: value });
    }
  }

  useEffect(() => {
    if (formState && formState.apiKeys && formState.category && formState.clientId) {
      checkAPIKeys(formState.clientId);
    }
    // eslint-disable-next-line
  }, [formState.category]);

const checkAPIKeys = () => {
    if (formState.clientId && !formState.apiKeys) {
        findApiKeys(formState.clientId);
    }
    return null;
}

  const findApiKeys = async (clientId) => {
    try {
    setSpinner(true);
    const myInit0 = {
        queryStringParameters: {
          clientId: clientId
        },
      };
      // find API keys
      await API.get("developerApi", "/findApiKeys", myInit0)
      .then((response) => {
        console.log(response);
        if (response.length > 0) {
            const st = {
            ...formState,
            username: "",
            password: "",
            apiKeys: true
            }
            console.log(st);
            setFormState(st);
            addToast({ variant: 'success', message: `Keys Found in SSM!` });
        } else {
            addToast({ variant: 'warning', message: `Keys not in SSM!` });
        }
        setSpinner(false);
      },
      (err) => {
        console.log("Error getting API Keys", err);
        addSnack({ variant: 'danger', message: `Couldn't find keys!` });
        setSpinner(false);
      })
    } catch (err) {
      console.log("error getting categories", err);
      setSpinner(false);
      return false;
    }
  }
async function storeKeys() {
    try {
      setSpinner(true);
      if (!formState.clientId) {
        addSnack({ variant: 'danger', message: `Need client id to store API Keys!` });
        setSpinner(false);
        return true;
      }
      if (formState.username !== "" && formState.password !== "") {
        const myInit = {
          queryStringParameters: {
            secureString: `${formState.username}:${formState.password}`,
            clientId: formState.clientId,
            overwrite: formState.updateKeys,
            env: env
          },
        };
        await API.post("developerApi", "/storeKeys", myInit);
        addSnack({ variant: 'success', message: 'API keys stored successfully!' });
        updateRequest();
      } else {
        addSnack({ variant: 'danger', message: `Enter API username and password!` });
        setSpinner(false);
        return true;
      }
    } catch (err) {
      console.log("Error Storing Keys:", err);
      addSnack({ variant: 'danger', message: 'Error Storing Keys' });
    }
    setSpinner(false);
  }

const updateRequest = async () => {
    addSnack({ variant: 'success', message: 'Request saved successfully!' });
    const st = {
      ...formState,
      username: "",
      password: "",
      apiKeys: true
    }
    setFormState(st);
  }

  return (
<Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  marginTop: "20px",
                  fontSize: "20px",
                }}
              >
            <Heading mb={2} mt={2} variant="h4">Admin Section</Heading>
            <Card variation="outlined" style={styles.adminSpace}>
            <Heading mb={3} variant="h5">Store API keys in the Parameter Store</Heading>
            <TextField
              onChange={(event) => setInput("clientId", event.target.value)}
              size="small"
              value={formState.clientId}
              label="Client Id"
              style={styles.inputWhite}
              //isDisabled={formState.apiKeys ? true : false}
            />
             <LumosButton variant="primary" onClick={() => { checkAPIKeys(); }}
                sx={{marginBottom: "15px"}}
              >
                Check if API keys already exist
              </LumosButton>
            {formState.apiKeys && formState.clientId !== "" && (
              <Alert
                variation="success"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys in SSM"
                style={styles.mb}
              ></Alert>
            )}
            {!formState.apiKeys && formState.clientId !== "" && (
              <>
              <Alert
                variation="warning"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys NOT in SSM"
                style={styles.mb}
              ></Alert>
              <LumosButton variant="secondary" onClick={() => { setInput("apiKeys", true); }}
                sx={{marginBottom: "15px"}}
              >
                API keys already stored
              </LumosButton>
             </>
            )}
            <LumosCard
                    header={{
                      title: "Store API Keys",
                    }}
                  >
                  <PasswordField
                    onChange={(event) => setInput("username", event.target.value)}
                    size="small"
                    style={styles.inputPassword}
                    value={formState.username}
                    label="Username"
                    isDisabled={formState.clientId === "" ? true : false}
                  />
                  <Divider
                    orientation="horizontal"
                    style={styles.divider}
                    size="small"
                  />
                  <PasswordField
                    onChange={(event) => setInput("password", event.target.value)}
                    size="small"
                    style={styles.inputPassword}
                    value={formState.password}
                    label="Password"
                    isDisabled={formState.clientId === "" ? true : false}
                  />
                  {formState.apiKeys &&
                  <SwitchField
                    isDisabled={false}
                    style={styles.switch}
                    label="Update API Keys"
                    labelPosition="end"
                    size="default"
                    isChecked={formState.updateKeys}
                    onChange={(event) => setInput("updateKeys", event.target.checked)}
                  />
                  }
                  <Divider
                    orientation="horizontal"
                    style={styles.divider}
                    size="small"
                  />
                  <LumosButton onClick={() => storeKeys()}
                    sx={{marginTop: "5px"}}
                  >
                    Store keys
                  </LumosButton>
            </LumosCard>
            </Card>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
              </Heading>
            </Box>
            </div>
        </>
    )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "20px 20px"
  },
  green: {
    color: "#green"
  },
  divider: { margin: "5px 0", color: "white", borderColor: "white" },
  divider2: { margin: "10px 0", color: "white", borderColor: "white" },
  space: { marginTop: "20px" },
  mb: { marginBottom: "20px" },
  mx: { margin: "20px 0"},
  statusBox: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "start"
  },
  tag: {
    margin: "20px 10px"
  },
  adminSpace: {
    marginBottom: "20px",
    backgroundColor: "#e8e8e8"
  },
  app: { marginBottom: 15 },
  input: {
     marginBottom: 10,
    padding: 8,
    fontSize: 14
  },
  inputError : {
    padding: 8,
    fontSize: 14
  },
  inputPassword : {
    padding: 8,
    fontSize: 14,
    fontWeight: 100
  },
  inputWhite: {
    marginBottom: 20,
    padding: 8,
    fontSize: 14,
    fontWeight: 100,
    backgroundColor: "white"
  },
  switch: {
    fontSize: 14,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
     marginBottom: "20px"
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
    fontSize: "13px"
  }
};

export default App;