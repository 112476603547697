export const listSalesforceURLs = /* GraphQL */ `
    query AllRequests {
    searchRequestss(sort: {direction: desc, field: createdAt}) {
        items {
        id
        salesforce
        salesforceId
        createdAt
        updatedAt
        }
    }
    }
`;

export const reqByClientId = /* GraphQL */ `
  query reqByClientId(
    $clientId: String
  ) {
    reqByClientId(
      clientId: $clientId
    ) {
      items {
        id
        accountName
        clientId
        status
        offer
      }
    }
  }
`;

export const listSharedApps = /* GraphQL */ `
  query ListSharedApps(
    $filter: ModelSharedAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRequestss = /* GraphQL */ `
  query ListRequestss(
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const appsByClientId = /* GraphQL */ `
  query AppsByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSharedAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        submittingUser {
            email
            firstName
            lastName
            phone
            __typename
        }
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appsByAccountName = /* GraphQL */ `
  query AppsByAccountName(
    $accountName: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByAccountName(
      accountName: $accountName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsByStatus = /* GraphQL */ `
  query ReqsByStatus(
    $status: StatusEnum
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsByStatusSorted = /* GraphQL */ `
  query ReqsByStatusSorted(
    $status: StatusEnum
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsByStatusSorted(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reqsBySalesforceId = /* GraphQL */ `
  query ReqsBySalesforceId(
    $salesforceId: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqsBySalesforceId(
      salesforceId: $salesforceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSharedApps = /* GraphQL */ `
  query SearchSharedApps(
    $filter: SearchableSharedAppFilterInput
    $sort: SearchableSharedAppSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSharedApps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        clientId
        status
        partnerName
        offerId
        offerName
        dealerFee
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        states
        offers
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        displayIframe
        referenceId
        formUrl
        partnerWebsiteUrl
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchRequestss = /* GraphQL */ `
  query SearchRequestss(
    $filter: SearchableRequestsFilterInput
    $sort: SearchableRequestsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRequestss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountName
        clientId
        status
        salesforce
        salesforceId
        offer
        offersRequested
        states
        channel
        category
        loanAmount
        paymentAmount
        calculatorType
        integratorId
        redirectUrl
        submittingUser {
          email
          firstName
          lastName
          phone
          __typename
        }
        sendEmail
        referenceId
        contactEmail
        partnerEmail
        apiKeys
        notes
        requestNotes
        bundleClientId
        singleOfferLinks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;