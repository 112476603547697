import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { updateSharedApp, createSharedApp } from "../../graphql/mutations";
import { getRequests } from "../../graphql/queries";
import { appsByClientId } from "../../graphql/custom-queries";
import {
  Heading,
  Button as LumosButton,
  useNotify,
  Card as LumosCard,
  Collapsible
 } from "@loanpal/lumos__core";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import { Categories } from "../../utils/categories";
import { Status } from "../../utils/status";

import {
  // withAuthenticator,
  Authenticator,
  Button,
  TextField,
  SwitchField,
  Divider,
  SearchField,
  View,
  Flex,
  FieldGroupIconButton,
  Alert,
  SelectField
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const initialState = {
  id: "",
  clientId: "",
  status: "",
  partnerName: "",
  integratorId: "",
  channel: "",
  states: "",
  category: "",
  loanAmount: 0.0,
  paymentAmount: 0.0,
  calculatorType: "LA",
  redirectUrl: "",
  sendEmail: false,
  displayIframe: true,
  referenceId: "quickLink",
  formUrl: "",
  submittingUserFname: "",
  submittingUserLname: "",
  submittingUserEmail: "",
  submittingUserPhone: "",
  bundleClientId: "",
  singleOfferLinks: false
};

const Edit = () => {
  const [formState, setFormState] = useState(initialState);
  const [spinner, setSpinner] = useState(false);
  const { id, action } = useParams();
  const [placeholderUrl] = useState(
    `https://${window.location.host}/{clientId}/{id}`
  );
  let navigate = useNavigate();
  const { addSnack } = useNotify();

  console.log("id", id)

  useEffect(() => {
    if (id !== 'new' && action === undefined) {
      fetchSharedApp(id);
    } else if (id === 'new') {
      clearForm();
    } else if (action === 'request' && id !== "") {
      fetchRequest(id)
    }
    // eslint-disable-next-line
  }, [id]);

  function setInput(key, value) {
    if (key === "integratorId") {
      setFormState({ ...formState, [key]: value, referenceId: "integratorId" });
    } else {
      setFormState({ ...formState, [key]: value });
    }
  }

  const clearForm = () => {
    setFormState(initialState);
  }

  async function fetchSharedApp(id) {
    if (!id && id === "") return;
    try {
      const appData = await API.graphql(
        graphqlOperation(appsByClientId, { clientId: id })
      );
      const apps = appData.data.appsByClientId.items;
      console.log(apps);
      if (apps && apps.length > 0) {
        loadSharedApp(apps[0]);
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
    }
  }

  async function checkSharedAppExists(req) {
    if (!req.clientId && req.clientId === "") return;
    try {
      const appData = await API.graphql(
        graphqlOperation(appsByClientId, { clientId: req.clientId })
      );
      const apps = appData.data.appsByClientId.items;
      if (apps && apps.length > 0) {
        console.log(apps[0]);
        loadSharedApp(apps[0]);
      } else {
        loadRequest(req);
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
      return false;
    }
  }

  async function fetchRequest(id) {
    if (!id && id === "") return;
    try {
      const reqData = await API.graphql(
        graphqlOperation(getRequests, { id: id })
      );
      const req = reqData.data.getRequests;
      if (req) {
        checkSharedAppExists(req);
      }
    } catch (err) {
      console.log("error fetching Request", err);
    }
  }

  const loadRequest  = async (app) => {
    let keysExists = app.apiKeys;
    if (!app.apiKeys) {
      setSpinner(true);
      keysExists = await checkApiKeys(app.clientId);
      setSpinner(false);
    }
    const appState = {
      id: "",
      clientId: app.clientId,
      status: "",
      partnerName: app.accountName,
      integratorId: app.integratorId ? app.integratorId : "",
      redirectUrl: app.redirectUrl ? app.redirectUrl: "",
      channel: app.channel,
      states: app.states ? JSON.parse(app.states) : "",
      loanAmount: app.loanAmount,
      paymentAmount: app.paymentAmount,
      calculatorType: app.calculatorType,
      category: app.category,
      sendEmail: app.contactEmail,
      displayIframe: true,
      referenceId: app.referenceId,
      formUrl: "",
      submittingUserFname: app.submittingUser.firstName,
      submittingUserLname: app.submittingUser.lastName,
      submittingUserEmail: app.submittingUser.email,
      submittingUserPhone: app.submittingUser.phone,
      apiKeys: keysExists,
      bundleClientId: app.bundleClientId,
      singleOfferLinks: app.singleOfferLinks
    };
    setFormState(appState);
  };

  const loadSharedApp = async (app) => {
    let keysExists = app.apiKeys;
    if (!app.apiKeys) {
      setSpinner(true);
      keysExists = await checkApiKeys(app.clientId);
      setSpinner(false);
    }
    const appState = {
      id: app.id,
      clientId: app.clientId,
      status: app.status,
      partnerName: app.partnerName,
      integratorId: app.integratorId ? app.integratorId : "",
      redirectUrl: app.redirectUrl ? app.redirectUrl: "",
      channel: app.channel,
      states: app.states ? JSON.parse(app.states) : "",
      loanAmount: app.loanAmount,
      paymentAmount: app.paymentAmount,
      calculatorType: app.calculatorType,
      category: app.category,
      sendEmail: app.sendEmail,
      displayIframe: app.displayIframe,
      referenceId: app.referenceId,
      formUrl: app.formUrl,
      submittingUserFname: app.submittingUser.firstName,
      submittingUserLname: app.submittingUser.lastName,
      submittingUserEmail: app.submittingUser.email,
      submittingUserPhone: app.submittingUser.phone,
      apiKeys: keysExists,
      bundleClientId: app.bundleClientId,
      singleOfferLinks: app.singleOfferLinks
    };
    setFormState(appState);
  };

  const checkApiKeys = async (clientId) => {
    try {
      const myInit = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      const result = await API.get("developerApi", "/categories", myInit);
      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("error checking API keys");
      return false;
    }
  };

  const buildApp = (app, update) => {
    if (update)
      return {
        id: app.id ? app.id : "",
        clientId: app.clientId,
        status: app.status,
        partnerName: app.partnerName,
        integratorId: app.integratorId,
        redirectUrl: app.redirectUrl,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        paymentAmount: app.paymentAmount,
        calculatorType: app.calculatorType,
        states: JSON.stringify(app.states.replace(/\s/g, "")),
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        displayIframe: app.displayIframe,
        referenceId: app.referenceId,
        formUrl: app.formUrl,
        bundleClientId: app.bundleClientId,
        singleOfferLinks: app.singleOfferLinks
      };
    else
      return {
        clientId: app.clientId,
        status: app.status,
        partnerName: app.partnerName,
        integratorId: app.integratorId,
        redirectUrl: app.redirectUrl,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        paymentAmount: app.paymentAmount,
        calculatorType: app.calculatorType,
        states: JSON.stringify(app.states.replace(/\s/g, "")),
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        displayIframe: app.displayIframe,
        referenceId: app.referenceId,
        formUrl: app.formUrl,
        bundleClientId: app.bundleClientId,
        singleOfferLinks: app.singleOfferLinks
      };
  };

  async function addSharedApp() {
    try {
      setSpinner(true);
      if (
        !formState.clientId ||
        !formState.channel ||
        !formState.submittingUserEmail ||
        !formState.submittingUserFname ||
        !formState.submittingUserLname
      )
        return;
      if (!formState.sendEmail && !formState.displayIframe) {
        return;
      }
      const app = buildApp(formState, formState.id !== "");
      if (formState.id !== "") {
        await API.graphql(graphqlOperation(updateSharedApp, { input: app }));
         addSnack({ variant: 'success', message: 'Shared App saved successfully!' });
      } else {
        const result = await API.graphql(
          graphqlOperation(createSharedApp, { input: app })
        );
        const updatedApp = buildApp(formState, true);
        updatedApp.id = result.data.createSharedApp.id;
        if (formState.integratorId && formState.integratorId !== "") {
          updatedApp.formUrl = `https://${window.location.host}/${result.data.createSharedApp.integratorId}/${result.data.createSharedApp.clientId.toLowerCase()}/${result.data.createSharedApp.id}`;
        } else if (formState.bundleClientId && formState.bundleClientId !== "") {
          updatedApp.formUrl = `https://${window.location.host}/glp/${result.data.createSharedApp.clientId.toLowerCase()}/${result.data.createSharedApp.bundleClientId.toLowerCase()}/${result.data.createSharedApp.id}`;
        } else {
          updatedApp.formUrl = `https://${window.location.host}/${result.data.createSharedApp.clientId.toLowerCase()}/${result.data.createSharedApp.id}`;
        }
        await API.graphql(
          graphqlOperation(updateSharedApp, { input: updatedApp })
        );
         addSnack({ variant: 'success', message: 'Shared App created successfully!' });
         loadSharedApp(updatedApp);
         navigate(`../edit/${updatedApp.clientId}`, { replace: false });
      }
    } catch (err) {
      console.log("Error saving/creating configuration:", err);
      addSnack({ variant: 'danger', message: err.errors[0].message });
    }
    setSpinner(false);
  }

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      err.log("Failed to copy!");
    }
  };

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Heading mb={3} variant="h3">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <View>{id === 'new' ? 'New' : '' } GoodLink Details</View>
                <SearchField
                  label="Search"
                  placeholder="search by Client Id..."
                  size="small"
                  onSubmit={(e) => fetchSharedApp(e)}
                  onClear={() => navigate(`../edit/new`, { replace: false })}
                />
              </Flex>
            </Heading>
            <TextField
              size="small"
              value={formState.id}
              label="Id"
              style={styles.input}
              isDisabled
            />
            <SelectField
              style={styles.input}
              label="Status"
              value={formState.status}
              onChange={(e) => setInput("status", e.target.value)}
              size="small"
            >
              <option value="">Please select a Status</option>
              <option value="ACTIVE">{Status.ACTIVE}</option>
              <option value="INPROGRESS">{Status.INPROGRESS}</option>
              <option value="NOTACTIVE">{Status.NOTACTIVE}</option>
              <option value="PENDING">{Status.PENDING}</option>
              <option value="BLOCKED">{Status.BLOCKED}</option>
              <option value="TEST">{Status.TEST}</option>
            </SelectField>
            <TextField
              onChange={(event) => setInput("clientId", event.target.value)}
              size="small"
              value={formState.clientId}
              label="Client Id"
              style={styles.input}
            />
            <TextField
              onChange={(event) => setInput("partnerName", event.target.value)}
              size="small"
              value={formState.partnerName}
              label="Partner Name"
              style={styles.input}
            />
            <TextField
              onChange={(event) => setInput("channel", event.target.value)}
              size="small"
              style={styles.input}
              value={formState.channel}
              label="Offer channel"
            />
            <SelectField
              style={styles.input}
              label="Category"
              value={formState.category}
              onChange={(e) => setInput("category", e.target.value)}
              size="small"
            >
              <option value="">Please select a Category</option>
              <option value={Categories.HOME_IMPROVEMENT.cat}>{Categories.HOME_IMPROVEMENT.cat}</option>
              <option value={Categories.ROOF.cat}>{Categories.ROOF.cat}</option>
              <option value={Categories.SOLAR.cat}>{Categories.SOLAR.cat}</option>
              <option value={Categories.BATTERY.cat}>{Categories.BATTERY.cat}</option>
            </SelectField>
            <TextField
             onChange={(event) => setInput(`${formState.calculatorType === "PA" ? "paymentAmount" : "loanAmount"}`, event.target.value)}
              size="small"
              style={styles.input}
              descriptiveText="Two point precision decimal."
              value={formState.calculatorType === "PA" ? formState.paymentAmount : formState.loanAmount}
              label={`Average ${formState.calculatorType === "PA" ? "Payment" : "Loan"}  Amount`}
            />
            <TextField
              label="Submitting User Email"
              onChange={(event) =>
                setInput("submittingUserEmail", event.target.value)
              }
              size="small"
              style={styles.input}
              value={formState.submittingUserEmail}
            />
            <TextField
              label="Submitting User First Name"
              onChange={(event) =>
                setInput("submittingUserFname", event.target.value)
              }
              size="small"
              style={styles.input}
              value={formState.submittingUserFname}
            />
            <TextField
              label="Submitting User Last Name"
              onChange={(event) =>
                setInput("submittingUserLname", event.target.value)
              }
              size="small"
              style={styles.input}
              value={formState.submittingUserLname}
            />
            <TextField
              label="Submitting User Phone"
              onChange={(event) =>
                setInput("submittingUserPhone", event.target.value)
              }
              size="small"
              descriptiveText="10 digits, no other characters"
              style={styles.input}
              value={formState.submittingUserPhone}
            />
            <SelectField
              style={styles.input}
              label="Reference logic"
              value={formState.referenceId}
              onChange={(e) => setInput("referenceId", e.target.value)}
              size="small"
            >
              <option value="quickLink">QuickLink</option>
              <option value="mobilePhone">Mobile Phone</option>
              <option value="lastName">Last Name</option>
              <option value="integratorId">Integrator Id</option>
              <option value="goodleapplus">GoodLeapPlus</option>
            </SelectField>
            <SwitchField
              isDisabled={false}
              style={styles.switch}
              label="Send Email"
              labelPosition="end"
              size="default"
              isChecked={formState.sendEmail}
              onChange={(event) => setInput("sendEmail", event.target.checked)}
            />
            <SwitchField
              isDisabled={false}
              style={styles.switch}
              label="Display Form"
              labelPosition="end"
              size="default"
              isChecked={formState.displayIframe}
              onChange={(event) =>
                setInput("displayIframe", event.target.checked)
              }
            />
            {/* <SwitchField
              isDisabled={false}
              style={styles.switch}
              label="Single Offer Links in email"
              description="If ON it will send single offer links in email"
              labelPosition="end"
              size="default"
              isChecked={formState.singleOfferLinks}
              onChange={(event) => setInput("singleOfferLinks", event.target.checked)}
            /> */}
            <TextField
              label="Form URL"
              onChange={(event) => setInput("formUrl", event.target.value)}
              size="small"
              style={styles.input}
              value={formState.formUrl}
              placeholder={placeholderUrl}
              innerEndComponent={
                <FieldGroupIconButton
                  ariaLabel="Copy"
                  variation="link"
                  onClick={() => copyToClipBoard(formState.formUrl)}
                  height="70%"
                >
                  <i className="gg-clipboard"></i>
                </FieldGroupIconButton>
              }
            />
            {formState.apiKeys && formState.clientId !== "" && (
              <Alert
                variation="success"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys in SSM"
              ></Alert>
            )}
            {!formState.apiKeys && formState.id !== "" && (
              <Alert
                variation="warning"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys NOT in SSM"
              ></Alert>
            )}
            {!formState.sendEmail && !formState.displayIframe && (
              <Alert
                variation="error"
                isDismissible={false}
                hasIcon={true}
                style={styles.space}
                heading="sendEmail and displayIframe actions can't be both false"
              ></Alert>
            )}
                         <Collapsible title="Special Features">
            <LumosCard
                header={{
                  title: "Features for Corporate/Integrator accounts",
                }}
                sx={{ marginTop: 20, marginBottom: 20 }}
              >
            <SelectField
              style={styles.input}
              label="Calculator Type"
              value={formState.calculatorType}
              onChange={(e) => setInput("calculatorType", e.target.value)}
              size="small"
              width="25%"
            >
              <option value=""></option>
              <option value="LA">Loan Amount</option>
              <option value="PA">Payment Amount</option>
            </SelectField>
            <TextField
              onChange={(event) => setInput("integratorId", event.target.value)}
              size="small"
              value={formState.integratorId}
              label="Integrator Id"
              style={styles.input}
              // descriptiveText={"Important! Currently this only works for Daikin where calculator leads with payment amount."}
            />
            <TextField
              onChange={(event) => setInput("redirectUrl", event.target.value)}
              size="small"
              value={formState.redirectUrl}
              label="Redirect Url"
              style={styles.input}
              descriptiveText={"Important! This will only work if client is configured for this feature."}
            />
            <TextField
              onChange={(event) => setInput("bundleClientId", event.target.value)}
              size="small"
              value={formState.bundleClientId}
              label="GoodLeap Plus Bundle ClientId"
              style={styles.input}
            />
            </LumosCard>
            </Collapsible>
            <Divider
              orientation="horizontal"
              style={styles.divider}
              size="small"
            />
            <Button
              style={
                !formState.sendEmail && !formState.displayIframe
                  ? styles.buttonDisabled
                  : styles.button
              }
              isFullWidth={false}
              isDisabled={!formState.sendEmail && !formState.displayIframe}
              onClick={addSharedApp}
            >
              Save partner config
            </Button>
            <Divider
              orientation="horizontal"
              style={styles.divider}
              size="small"
            />
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "20px 20px"
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 20,
    padding: 8,
    fontSize: 16,
  },
  switch: {
    marginBottom: 10,
    fontSize: 16,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
};

export default Edit;
