/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSharedApp = /* GraphQL */ `
  mutation CreateSharedApp(
    $input: CreateSharedAppInput!
    $condition: ModelSharedAppConditionInput
  ) {
    createSharedApp(input: $input, condition: $condition) {
      id
      clientId
      status
      partnerName
      offerId
      offerName
      dealerFee
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      states
      offers
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      displayIframe
      referenceId
      formUrl
      partnerWebsiteUrl
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSharedApp = /* GraphQL */ `
  mutation UpdateSharedApp(
    $input: UpdateSharedAppInput!
    $condition: ModelSharedAppConditionInput
  ) {
    updateSharedApp(input: $input, condition: $condition) {
      id
      clientId
      status
      partnerName
      offerId
      offerName
      dealerFee
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      states
      offers
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      displayIframe
      referenceId
      formUrl
      partnerWebsiteUrl
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSharedApp = /* GraphQL */ `
  mutation DeleteSharedApp(
    $input: DeleteSharedAppInput!
    $condition: ModelSharedAppConditionInput
  ) {
    deleteSharedApp(input: $input, condition: $condition) {
      id
      clientId
      status
      partnerName
      offerId
      offerName
      dealerFee
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      states
      offers
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      displayIframe
      referenceId
      formUrl
      partnerWebsiteUrl
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoodLinks = /* GraphQL */ `
  mutation CreateGoodLinks(
    $input: CreateGoodLinksInput!
    $condition: ModelGoodLinksConditionInput
  ) {
    createGoodLinks(input: $input, condition: $condition) {
      id
      clientId
      custFirstName
      custLastName
      custEmail
      custMobile
      custState
      custLoanAmount
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoodLinks = /* GraphQL */ `
  mutation UpdateGoodLinks(
    $input: UpdateGoodLinksInput!
    $condition: ModelGoodLinksConditionInput
  ) {
    updateGoodLinks(input: $input, condition: $condition) {
      id
      clientId
      custFirstName
      custLastName
      custEmail
      custMobile
      custState
      custLoanAmount
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoodLinks = /* GraphQL */ `
  mutation DeleteGoodLinks(
    $input: DeleteGoodLinksInput!
    $condition: ModelGoodLinksConditionInput
  ) {
    deleteGoodLinks(input: $input, condition: $condition) {
      id
      clientId
      custFirstName
      custLastName
      custEmail
      custMobile
      custState
      custLoanAmount
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRequests = /* GraphQL */ `
  mutation CreateRequests(
    $input: CreateRequestsInput!
    $condition: ModelRequestsConditionInput
  ) {
    createRequests(input: $input, condition: $condition) {
      id
      accountName
      clientId
      status
      salesforce
      salesforceId
      offer
      offersRequested
      states
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      referenceId
      contactEmail
      partnerEmail
      apiKeys
      notes
      requestNotes
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequests = /* GraphQL */ `
  mutation UpdateRequests(
    $input: UpdateRequestsInput!
    $condition: ModelRequestsConditionInput
  ) {
    updateRequests(input: $input, condition: $condition) {
      id
      accountName
      clientId
      status
      salesforce
      salesforceId
      offer
      offersRequested
      states
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      referenceId
      contactEmail
      partnerEmail
      apiKeys
      notes
      requestNotes
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequests = /* GraphQL */ `
  mutation DeleteRequests(
    $input: DeleteRequestsInput!
    $condition: ModelRequestsConditionInput
  ) {
    deleteRequests(input: $input, condition: $condition) {
      id
      accountName
      clientId
      status
      salesforce
      salesforceId
      offer
      offersRequested
      states
      channel
      category
      loanAmount
      paymentAmount
      calculatorType
      integratorId
      redirectUrl
      submittingUser {
        email
        firstName
        lastName
        phone
        __typename
      }
      sendEmail
      referenceId
      contactEmail
      partnerEmail
      apiKeys
      notes
      requestNotes
      bundleClientId
      singleOfferLinks
      createdAt
      updatedAt
      __typename
    }
  }
`;
