import React from "react";
import { Box } from "theme-ui";
import { Card, Heading, Icon } from "@loanpal/lumos__core";
import IconList from "./icon-list";

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        pt: 6,
        "@media screen and (max-width: 80em)": {
          pt: 4,
        },
      }}
    >
      <Box sx={{ width: "100%", mx: "auto", maxWidth: 500 }}>
        <Card variant="float" sx={{ px: 4, pb: 4 }}>
          <Heading
            variant="h3"
            as="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Icon variant="color-info" sx={{ mr: 3 }} size="md" />
            Page not found!
          </Heading>
          <Box
            sx={{
              mt: 1,
              pb: 3,
              mb: 3,
              borderBottom: "1px solid",
              borderColor: "neutral.l3",
            }}
          >
            Sorry, but the page you were looking for could not be found.
          </Box>
          <section>
            <Heading variant="h5" as="div">
              What should I do?
            </Heading>
            <IconList icon="envelope">
              You can <a href="mailto:integrations@goodleap.com">Email Us</a>{" "}
              and we will get back to you promptly.
            </IconList>
          </section>
        </Card>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
