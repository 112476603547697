import { DateTime } from 'luxon';
const numeral = require("numeral");
const moment = require("moment-timezone");

export const stringToNumber = (string) => numeral(string).value();
export const termRate = (term, rate) => `${term} Year Loan ${rate}%`;
export const numberToMoney = (num) =>  numeral(num).format("$0,0[.]00");
export const numberToMoneyNoDecimal = (num) => numeral(num).format("$0,0");
export const numberFormatted = (num) => numeral(num).format("0,0[.]00");
export const moneyToNumber = (money) => numeral(money).value();
export const moneyStringToNumber = (money) => Number.parseFloat(money.replace(/[^0-9.]/g, ''));
export const validateMoneyInput = (value) => value && /^[0-9,.$]*$/.test(value);
export const phone = (phone) => {
  if (!phone) return "";
  let formattedPhone = phone.replace("+1", "").replace(/\D/g, "");
  formattedPhone =
    formattedPhone.charAt(0) === "1"
      ? formattedPhone.substr(1)
      : formattedPhone;
  return `(${formattedPhone.substring(0, 3)}) ${formattedPhone.substring(
    3,
    6
  )}-${formattedPhone.substring(6, 10)}`;
};
export const phoneAWS = (phone) => {
  if (!phone) return "";
  const rawPhone = phone.replace(/[^0-9]+/g, ""); // (916)929-3832 => 9169193832
  const phonePlusCountryCode = `+1${rawPhone}`;
  return phonePlusCountryCode;
};
export const phoneDigits = (phone) => {
  if (!phone) return "";
  const rawPhone = phone.replace(/[^0-9]+/g, ""); // (916)929-3832 => 9169193832
  return rawPhone;
};
export const capitalizeFirstChar = (str) =>
  str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
export const dateStr = (date) => {
  if (!date) return "";
  return moment(date).local().format("DD-MMM-YYYY");
};
export const dateTimeStr = (date) => {
  if (!date) return "";
  const dateTime = moment(date).local().format("MM/DD/YYYY h:mmA");
  const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
  return `${dateTime} ${timezone}`;
};
export const fileSizeBytes = (bytes, decimals) => {
  if (0 === bytes) return "0 Bytes";
  var c = 1024,
    d = decimals || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(bytes) / Math.log(c));
  return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};
export const formatDate = function (date, format) {
    return format ? DateTime.fromISO(date).toLocaleString(format) : DateTime.fromISO(date).toLocaleString();
};

export const formatDateTime = function (date) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
};
